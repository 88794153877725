import moment from 'moment'
import { getLocalDate, normalize, getSportId, createChampionshipHash } from '../utils'

export function isSameMatchOdd(odd, match) {
  return odd.match.id_partida == match.id_partida || odd.match.id_partida == match.cotacao_api_id
}

export function isMainOdd(odd) {
  return [1, 2, 3, 2360, 2361, 2405, 2406, 2407, 2408, 2409, 2410, 2621, 2622].includes(+odd.id_tipo)
}

function showMatch(match, settings, live) {
  const useNonCentralId = live || !match.cotacao_api_id
  const canceledMatch = settings.partidas[match[useNonCentralId ? 'id_partida' : 'cotacao_api_id']]
  const matchDate = getLocalDate(match.data, settings.fuso_horario)
  let hideMatch = !!canceledMatch

  if (!hideMatch && matchDate.isoWeekday() === 7) {
    const blockTime = getLocalDate(`${matchDate.format('YYYY-MM-DD')} ${settings.horario_bloqueio_partidas}`);
    hideMatch = blockTime.diff(matchDate, 'seconds') <= 0;
  }

  const championshipHash = createChampionshipHash(match, ['codigo_pais', 'campeonato', 'esporte'])
  const championship = settings.campeonatos[championshipHash]

  return (!championship || (live ? championship.ao_vivo : championship.status)) && !hideMatch
}

export function generateMatch(match, settings, live = false) {
  if (!showMatch(match, settings, live)) {
    return null
  }

  const suffix = live ? 'ao_vivo' : 'pre_jogo'
  const statusKey = `status_${suffix}`
  const adjustKey = `ajuste_${suffix}`

  if (!live && match.cotacoes.length > 3 && match.cotacao_api_id && settings.tipos && settings.tipos[match.cotacao_api_id]) {
    const tipos = Object.assign({}, settings.tipos[match.cotacao_api_id])

    match.cotacoes.map(c => {
      if (tipos[c.id_tipo]) {
        delete tipos[c.id_tipo]
      }
    })

    Object.values(tipos).map(tipo => {
      match.cotacoes.push(tipo)
    })
  }

  const cotacoes = match.cotacoes.filter(c => {
    const category = settings.categorias[c.cat_id]
    const type = category && category.tipos[c.id_tipo]

    return type && category[statusKey] === 1 && type[adjustKey] > 0
  }).map(c => {
    if (settings.tipos && match.cotacao_api_id && settings.tipos[match.cotacao_api_id] && settings.tipos[match.cotacao_api_id][c.id_tipo]) {
      c.cotacao = settings.tipos[match.cotacao_api_id][c.id_tipo].cotacao
    }

    if (+c.cotacao <= 1.0 || (!match.cotacao_api_id && typeof c._cotacao !== 'undefined')) {
      return c
    }

    const oddCategory = settings.categorias[c.cat_id]
    const maxValue = oddCategory.tipos[c.id_tipo].cotacao_maxima.toFixed(2)
    const adjust = oddCategory.tipos[c.id_tipo][adjustKey] / 100
    let oddValue = (c.cotacao * adjust).toFixed(2)
    c.categoria = oddCategory.titulo
    c._cotacao = c.cotacao

    const favoriteTypes = {
      1: [1, 2],
      4: [14, 15],
      16: [52, 54],
      64: [2284, 2285]
    };

    const allowedTypes = favoriteTypes[c.cat_id]
    if (allowedTypes && allowedTypes.includes(+c.id_tipo)) {
      const odds = match.cotacoes
        .filter(cat => cat.cat_id == c.cat_id && allowedTypes.includes(+cat.id_tipo))
        .map(o => o._cotacao || o.cotacao)
        .sort()
      const type = c._cotacao === odds[0] ? 'favorito' : 'zebra'
      oddValue = (+oddValue * settings[`cotacao_${type}`] / 100).toFixed(2)
    }

    c.cotacao = +oddValue > maxValue ? +maxValue : +oddValue

    return c
  })

  const [homeOdd, awayOdd, drawOdd] = cotacoes
    .filter(o => isMainOdd(o))
    .sort((a,b) => a.id_tipo - b.id_tipo)

  if (!match.chave) {
    match.chave = [
      match.pais, match.campeonato, match.mandante, match.visitante
    ].join('/')
  }

  const chave = normalize(match.chave)

  return Object.assign(match, {homeOdd, awayOdd, drawOdd, chave, cotacoes})
}

export function generateMatches(matches, settings, live = false, selectedDate = null, selectedSport = null) {
  if (live) {
    matches = matches.filter(m => m.tempo_jogo < settings.tempo_bloqueio_ao_vivo)
  }

  if (selectedDate && selectedSport && settings.partidas_manuais && settings.partidas_manuais[selectedSport] && settings.partidas_manuais[selectedSport][selectedDate] && settings.partidas_manuais[selectedSport][selectedDate].length > 0) {
    matches = matches.concat(settings.partidas_manuais[selectedSport][selectedDate])
  }

  matches = matches
    .filter(match => showMatch(match, settings, live))
    .map(match => generateMatch(match, settings, live))

  if (!live) {
    matches = matches.filter((m) => {
      const matchDate = getLocalDate(m.data, settings.fuso_horario)
      const minutes = matchDate.diff(moment(), 'minutes')
      return minutes > 0;
    }).map(m => {
      const key = createChampionshipHash(m, ['codigo_pais', 'campeonato', 'esporte'])
      m.favorito = m.favorito || (settings.campeonatos[key] && settings.campeonatos[key].favorito ? settings.campeonatos[key].favorito : 0)

      return m
    }).sort((a,b) => (b.favorito - a.favorito))
  }

  return matches
}

export function generateChampionships(matches, settings, live = false) {
  const championshipsById = {}
  let _championships = []

  matches.forEach((match) => {
    const key = championshipName(match)
    const hashKey = createChampionshipHash(match, ['codigo_pais', 'campeonato', 'esporte'])
    const championship = settings.campeonatos[hashKey]

    if (!championship || championship.status) {
      if (!(key in championshipsById)) {
        championshipsById[key] = {
          key,
          name: match.campeonato,
          country: match.pais,
          flag: match.bandeira_pais || `/images/intl-${settings.tema_padrao ? 'light' : 'dark'}.png`,
          favorite: match.favorito || (championship && championship.favorito),
          live: true || (!!championship && !!championship.ao_vivo),
          matches: []
        }

        _championships.push(championshipsById[key])
      }

      championshipsById[key].matches.push(match)
    }
  })

  _championships = _championships.sort((a, b) => {
    if (a.favorite === b.favorite)
      return a.key.localeCompare(b.key)
    return b.favorite - a.favorite
  })

  if (live) {
    _championships = _championships
      .filter(championship => !!championship.live)
      .sort((a, b) => {
        if (a.favorite)
          return -1

        if (b.favorite)
          return 1

        return 0
      })
  }

  return _championships
}

export function generateOddsData(match, live = false, categories = []) {
  if (!match) {
    return;
  }

  const matchTimes = {
    fullTime: {id: 3, categories: {}},
    firstTime: {id: 1, categories: {}},
    secondTime: {id: 2, categories: {}},
  }

  match.cotacoes.forEach((odd) => {
    for (let timeKey in matchTimes) {
      if (!matchTimes.hasOwnProperty(timeKey)) continue

      const timeData = matchTimes[timeKey]

      if (!live && timeData.id != odd.tempo_partida) continue
      if (live && timeKey !== 'fullTime') continue
      if (!(odd.cat_id in timeData.categories)) {
        if (live && categories.length > 0) {
          odd.ordem_cat = 0;
          let [filteredCategory] = categories.filter(category => category.titulo == odd.categoria);

          if (typeof filteredCategory !== 'undefined') {
            odd.ordem_cat = filteredCategory.ordem;
          }
        }

        timeData.categories[odd.cat_id] = {
          id: odd.cat_id,
          name: odd.categoria,
          odds: [],
          ordem: odd.ordem_cat
        }
      }
      timeData.categories[odd.cat_id].odds.push(odd)
      break
    }
  })

  if (!live && match.jogadores && !Array.isArray(match.jogadores)) {
    const player_types = {
      2438: 'first',
      2439: 'last',
      2440: 'first',
      2620: 'last',
      2435: 'first',
      2436: 'last',
      2437: 'anytime'
    }

    Object.values(categories || {}).filter(category =>
      [112, 113, 124].includes(category.id_cat) && match.jogadores[category.id_cat]
    ).map(category => {
      const playerCategory = match.jogadores[category.id_cat]
      const types = Object.values(category.tipos)
      playerCategory.status = category.status_pre_jogo

      if (!playerCategory.status) {
        delete match.jogadores[category.id_cat]
        return
      }

      types.map(type => {
        const players_id = Object.keys(playerCategory.odds)
        players_id.map(player_id => {
          const type_name = player_types[type.id_tipo]
          const player_type = playerCategory.odds[player_id][type_name]
          if (!player_type) return

          const _odd = player_type.cotacao
          const odd = _odd * type.ajuste_pre_jogo / 100

          player_type.cotacao = Math.min(odd, category.tipos[type.id_tipo].cotacao_maxima)
        })
      })
    })
  }

  const oddsData = {
    fullTime: Object.values(matchTimes.fullTime.categories).sort((a,b) => a.ordem - b.ordem),
    firstTime: Object.values(matchTimes.firstTime.categories).sort((a,b) => a.ordem - b.ordem),
    secondTime: Object.values(matchTimes.secondTime.categories).sort((a,b) => a.ordem - b.ordem),
    players: match.jogadores
  }

  return oddsData
}

export function teamCrestImage(imageUrl) {
  return imageUrl ? imageUrl : '/images/shields/shield.png'
}

export function showCrest(sport) {
  return [3, 5, 6].indexOf(sport) === -1
}

export function generateMatchTableContent(data, sport, settings) {
  const sportId = getSportId(sport)
  const isSoccer = sportId === 1

  return `
  <html>
    <head>
      <style>
        *{margin:0;padding:0}body{font-family: Arial, sans-serif;width:100vw;color:black;-webkit-print-color-adjust:exact;color-adjust:exact;padding: 15px 5px 0 5px;}
        img{display:block;max-width:100%;height:auto;-webkit-print-color-adjust:exact;color-adjust:exact;}
        header{font-size: 2rem;display:flex;flex-direction:column;align-items:center;}
        .outline {border: 2px solid black;color: black;display:flex;justify-content:center;align-items:center;}
        .dark {border: 2px solid black;background-color: black;color: white;text-align:center}
        .text-uppercase{text-transform:uppercase}
        .text-center{text-align:center}
        .text-capitalize{text-transform:capitalize}
        .p-1{padding: 5px 0}
        .mt-2{margin-top: 10px;}
        .mb-1{margin-bottom: 5px;}.mb-2{margin-bottom: 10px;}.mb-3{margin-bottom: 25px;}
        .w-15{width:15vw}.w-32{width:32.5vw;}.w-49{width:49.25vw;}.w-85{width:83vw;}
        .table-row{display:flex;flex-direction:row;justify-content:space-between;}
      </style>
    </head>
    <body>
      <header class="mb-2">
        <img src="${settings.logo || '../../images/logo-dark.png'}" width="250" class="mb-2" />
        *** ${settings.esportes && settings.esportes[sport] && settings.esportes[sport].name || 'Futebol'} ***
      </header>
      <div class="outline w-100 p-1 mb-1 text-capitalize">
        ${data.day_week} - ${data.date}
      </div>
      ${data.championships.map((championship, i) => `
        <div class="outline w-100 p-1 mb-1 text-uppercase">
          ${championship.key}
        </div>
        ${championship.matches.map((match) => `
            <div class="table-row w-100 mb-1">
              <div class="outline w-15">
                ${moment(match.data).format('HH:mm')}
              </div>
              <div class="outline w-85">
                ${match.mandante} x ${match.visitante}
              </div>
            </div>
            <div class="table-row mb-1">
              ${match.cotacoes.map((cotacao) => `
                <div class="w-${isSoccer ? 32 : 49} outline">
                  ${cotacao.tipo}
                </div>`
              )}
            </div>
            <div class="table-row mb-1">
              ${match.cotacoes.map((cotacao) => `
                <div class="w-${isSoccer ? 32 : 49} outline">
                  ${(+cotacao.cotacao).toFixed(2)}
                </div>`
              )}
            </div>
          `).join('')}
        `).join('')}
      <div class="w-100 text-center mt-2">
        ${data.message}
      </div>
    </body>
  </html>
  `
}

export function getNameInitials(fullname) {
  const names = ('' + fullname).split(' ')
  const lastname = names.pop()
  const initials = names.map(n => {
    let name = (n.charAt(0) === n.charAt(0).toUpperCase() ? n.charAt(0) + '.' : n) + ' ';
    return name
  })
  return initials.join('') + lastname
}

export function championshipName(o) {
  if (o.pais && o.campeonato)
    return `${o.pais} - ${o.campeonato}`
  if (o.country && o.name)
    return `${o.country} - ${o.name}`
  return ''
}
